/*!
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.title {
  font-size: 18px;
  font-weight: bold;
}
.input {
  width: 100%;
  height: 48px;
  background: #F8F9FA 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  border: none;
  margin: 5px 0px 5px 0px;
  color: #2756FF;
}
.input:hover {
  border: 1px solid #2756FF;
}
.input:focus {
  border: 1px solid #2756FF;
}
.select {
  width: 100%;
  height: 48px;
  background: #F8F9FA 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  border: none;
  margin: 5px 0px 5px 0px;
  color: #2756FF;
}
.default{
  width: 100%;
  height: 48px;
  background: #F8F9FA 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  border: none;
  margin: 5px 0px 5px 0px;
  color:#808080;
}
.select select-item {
  width: 100%;
  height: 48px;
  background: #F8F9FA 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  border: none;
  color:#808080;
}
.buttons {
  margin: 32px 0 32px 0;
  text-align: right;
}

.btn {
  width: 100px;
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #2756FF;
  border-radius: 10px;
  opacity: 1;
  margin-left: 10px;
  margin-right: 10px;
  color: #2756FF;
  background-color: #F8F9FA;
}

.btn:hover {
  color: #F8F9FA;
  background-color: #2756FF;
}
