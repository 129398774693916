/*!
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.Frame {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-top: 1rem;
  overflow-y: auto;
}

.Frame.FullScreen {
  margin-top: 0;
}

.FrameBody {
  transition: all 0.3s;
  opacity: 1;
  padding: 15px 30px;
}

.FrameBody.NoPadding {
  padding: 0 !important;
}

.FrameBody.Contract {
  opacity: 0;
  height: 0;
  padding: 0;
  transition: all 0.3s;
}

.FrameHeader {
  height: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, .03);
  padding: 10px 10px 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  display: flex;
  color: #6c757d;
}

.FrameHeaderText {
  flex: 1;
}

.FrameButton {
  color: gray;
}

.FrameButton:focus {
  color: gray;
}

.FrameButton:hover {
  color: black;
}

.FrameButton.activate {
  color: black;
}

.ButtonArea {
  // margin: auto 18%;
}

.FullScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 12;
  display: flex;
  flex-direction: column;
}

.FullScreen > .FrameBody {
  flex: 1;
}

.FullScreen .NormalChart, .FullScreen .MetaChart {
  flex: 1;
}

.Chart {
  width: 100%;
  display: block;
  overflow: hidden;
}

.NormalChart {
  @extend .Chart;
  height: 417px;
}

.MetaChart {
  @extend .Chart;
  height: 517px;
}

.FlexContentWrapper {
  display: flex;
  flex-direction: column;
}

.FlexContentWrapper.DefaultLimitWrapper {
  height: 500px;
}

.FullScreen .FlexContentWrapper {
  height: 100% !important;
}

#toEditor:hover{
  color:rgb(20, 33, 55)
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 670px) {

  .FrameHeader{
    display: block;
  }

  // .ButtonArea {
  //   margin: auto 18%;
  //   display: flex;
  // }
}
