.code-mirror-builder{
    display: flex;
    padding: 5px 5px 5px 5px;
}

.selection-builder{
    display: block;

}

#submit-builder{
    width: 100%;
    display: flex;
    justify-content: right;
    padding-top: 10px;
}