

#graphSelectionContainer {
    position: relative;
}

#graphSelection {
    display: flex;
}

.ant-select{
    display: flex;
}